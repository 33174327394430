import React from 'react';
import Section from '../common/Section';
import Cart from '../common/Cart';
import lodash from 'lodash';
import toteUtils from '../utils/tote';


// When actions come into play, we will be able to see if the cart is built in the same way it was planned
const BuildCart = ({ rowCount, removeDigitsFromTote, columnCount, totes }) => {
    const [ startAt, setStartAt ] = React.useState(1);
    const toteValues = React.useMemo(() => totes.map(({ tote }, pos) => {
        if (pos >= lodash.size(totes)) {
            return null;
        }
        if (tote) {
            return `${tote}${removeDigitsFromTote ? '00' : ''}`;
        }

        const startAtValue = isNaN(parseInt(startAt, 10)) ? 1 : parseInt(startAt, 10);
        const value = 99900000000000 + ((pos + startAtValue) * (removeDigitsFromTote ? 100 : 1));

        return toteUtils.isTargetTote(value)
            ? value
            : `${99900000000000 + ((pos + 1) * (removeDigitsFromTote ? 100 : 1))}`;
    }), [ removeDigitsFromTote, startAt, totes ]);

    const isScanned = lodash.compact(lodash.map(totes, 'tote')).length === lodash.size(totes);

    return (
        <Section title="Build Cart">
            <Cart
                buildCart
                rowCount={rowCount}
                columnCount={columnCount}
                toteValues={toteValues}
                isToteScanned={(totePos) => !!(totes[totePos]?.tote)}
                startAt={startAt}
                setStartAt={setStartAt}
                showStartAt={!isScanned}
                // the purpose of this is to show a small icon telling whether this is the actual value or proposed by the picking assistante
            />

            <div style={{ paddingLeft: 12 }}>
                <div style={{ backgroundColor: '#cdcdcd', width: '100%', height: 8 }} />
            </div>
        </Section>
    );
};


export default BuildCart;
