import axios from 'axios';
import lodash from 'lodash';
import { camelCaseKeys, parseAddressId } from '../utils/toma';

const getBaseUrl = (envConfig) => {
    // return `http://localhost:8080`;

    const hostPrefix = lodash.includes([ 'ode', 'prod', 'uat' ], envConfig?.environmentTypeId)
        ? envConfig.environmentTypeId
        : 'nonprod'
    ;

    const baseDomain = envConfig.environmentTypeId === 'ode' ? envConfig?.baseDomain : 'tom.takeoff.com';

    return `https://${hostPrefix}.mobile.${baseDomain}`;
};

const getHeaders = ({ envConfig, xToken, addJsonContentType = false }) => {
    const headers = {
        'X-Token': xToken,
        'X-Env-Type': envConfig.environmentTypeId,
        'X-Retailer-Id': envConfig.retailerCodename,
        'X-Route-Id': envConfig.retailerCodename,
    };
    if (addJsonContentType) {
        headers['Content-Type'] = 'application/json';
    }
    return headers;
};


const assignFulfillmentTask = async ({ xToken, envConfig, mfcId }) => {
    const config = {
        method: 'POST',
        url: `${getBaseUrl(envConfig)}/api/retailers/${envConfig.retailerCodename}/mfcs/${mfcId}/fulfillmentTasks:assign`,
        headers: getHeaders({ envConfig, xToken, addJsonContentType: true }),
    };

    const response = await axios(config);
    const data = response.data;
    return data && !lodash.isEmpty(data) ? formatFulfillmentTask(data) : undefined;
};

const formatFulfillmentTask = (task) => {
    const items = task.items;
    delete task.items;
    task = camelCaseKeys(task, true);
    task.items = lodash.mapValues(items, (item) => camelCaseKeys(item, true));

    // For now We only make use of the 'action' key of the payload which
    // contains the original action as it was saved. We should think a bit harder about how to improve this
    task.actions = lodash.map(task.actions, (storedAction) => ({ ...storedAction.action }));

    // We don't do anything with our persisted actions serverside yet so we generate the updated view of the order from the actions
    // this basically has to mirror what our state reducer does to the task to keep things in sync
    const totes = lodash.reduce(task.totes, (result, tote) => {
        result[tote.tote] = {
            tote: tote.tote,
            collected: tote.collected,
            collectedFrom: {
                addressType: tote.collectedFromAddressType,
                addressId: tote.collectedFromAddressId,
            },
            stagedTo: {
                addressType: tote.stagedToAddressType,
                addressId: tote.stagedToAddressId,
            },
            staged: tote.staged,
        };
        return result;
    }, {});

    const pickDataByStepNumber = {};
    let totalPicked = 0;

    lodash.forEach(task.actions, (action) => {
        const stepNumber = lodash.has(action, 'stepNumber')
            ? action.stepNumber
            : action.pick?.stepNumber
            ;
        if (action.type === 'fulfillment.picking.stepManuallyCompleted' || action.type === 'fulfillment.picking.stepCompleted') {
            const step = lodash.find(task.steps, { number: stepNumber });
            step.completed = true;
        }
        else if (action.type === 'fulfillment.picking.itemPicked') {
            if (!pickDataByStepNumber[stepNumber]) {
                pickDataByStepNumber[stepNumber] = {
                    quantity: 0,
                    weight: 0,
                };
            }
            pickDataByStepNumber[stepNumber].quantity = pickDataByStepNumber[stepNumber].quantity + action.pick.quantity;
            pickDataByStepNumber[stepNumber].weight = pickDataByStepNumber[stepNumber].weight + (action.pick.weight || 0);
            totalPicked = totalPicked + action.pick.quantity;
            if (action.pick.toteId) { // an action can have a null toteId if is a zero-qty action
                totes[action.pick.toteId] = {
                    ...(totes[action.pick.toteId] || {}),
                    tote: action.pick.toteId,
                    collected: true,
                    // This is a little bit of a hack to distinguish totes added via manual picking
                    // from totes that need to be collected during consolidation.
                    manual: true,
                };
            }
        }
        else if (action.type === 'fulfillment.prepTotes.labelsGenerated') {
            task.toteLabels = action.labels;
        }
        else if (action.type === 'fulfillment.prepTotes.labelsPrintedNfc' || action.type === 'fulfillment.prepTotes.labelsPrintedNetwork') {
            task.labelsPrinted = true;
        }
        else if (action.type === 'fulfillment.picking.completed') {
            task.pickingCompleted = true;
        }
        else if (action.type === 'fulfillment.prepTotes.totesCollected') {
            task.totesCollected = true;
        }
        else if (action.type === 'fulfillment.bags.confirmed') {
            task.bagsConfirmed = true;
        }
        else if (action.type === 'fulfillment.prepTotes.consolidateOrderConfirmed') {
            task.consolidateOrderConfirmed = true;
        }
        else if (action.type === 'fulfillment.prepTotes.taskPacked') {
            task.packed = true;
        }
        else if (action.type === 'fulfillment.staging.taskStaged') {
            task.staged = true;
        }
        else if (action.type === 'fulfillment.staging.taskCompleted') {
            task.completed = true;
        }
    });

    // Set the various data we collected from the actions.
    // We'll try to make these work smoothly for the point when
    // the backend starts handling some of these updates to the
    // actual persisted task entities by just using the server
    // provided value if there is one
    task.totes = totes;
    task.totalPicked = task.totalPicked ? task.totalPicked : totalPicked;

    lodash.forEach(task.steps, (step) => {
        step.requested = {
            quantity: step.requested,
            unitOfMeasure: step.requestedUnitOfMeasure,
            weight: step.requestedWeight,
        };
        step.picked = {
            quantity: step.picked ? step.picked : pickDataByStepNumber[step.number]?.quantity || 0,
            weight: step.pickedWeight ? step.pickedWeight : pickDataByStepNumber[step.number]?.weight || 0,
        };
        step.completed = !!step.completed;

        delete step.pickedWeight;
        delete step.requestedUnitOfMeasure;
        delete step.requestedWeight;

        if (step.addressId) {
            step.parsedAddress = parseAddressId(step.addressId);
        }
        else {
            step.addressId = null;
            task.hasNoAddressItems = true;
        }
    });

    if (task.hasNoAddressItems) {
        const { manualSteps, noAddressSteps } = lodash.groupBy(
            task.steps,
            (step) => step.addressId ? 'manualSteps' : 'noAddressSteps',
        );

        task.steps = manualSteps || [];
        task.noAddressSteps = noAddressSteps;
    }
    else {
        task.noAddressSteps = [];
    }

    return task;
};

const formatTotesInfo = (data, orderId) => {
    data = camelCaseKeys(data) || {};
    const result = {
        rampState: {
            accounts: data.rampState?.accounts,
            balance: data.rampState?.balance,
        },
    };

    const orderToteIdsOnRamp = data.rampState?.orders[`z-${orderId}`];
    const ffTaskTotes = data.fulfillmentTask?.totes_to_collect || [];
    const taskToteByToteId = ffTaskTotes.reduce((acc, taskTote) => ({ ...acc, [taskTote.tote]: taskTote }), {});

    const allToteIds = lodash.uniq(
        lodash.compact(
            lodash.concat(Object.keys(taskToteByToteId), orderToteIdsOnRamp)
        )
    );
    const totes = {};
    lodash.forEach(allToteIds, (toteId) => {
        const taskTote = taskToteByToteId[toteId];
        totes[toteId] = {
            toteId,
            collected: !!taskTote?.collected,
            collectedFrom: taskTote?.collected_from_address_type,
            staged: !!taskTote?.staged,
            temperatureZone: taskTote?.temperature_zone,
        };
    });
    result.totes = totes;
    return result;
};


const getTaskTotes = async ({ envConfig, xToken, mfcId, orderId, fulfillmentTaskId }) => {
    const config = {
        method: 'GET',
        url: `${getBaseUrl(envConfig)}/api/retailers/${envConfig.retailerCodename}/mfcs/${mfcId}/fulfillmentTasks/${fulfillmentTaskId}/totes`,
        headers: getHeaders({ envConfig, xToken, addJsonContentType: true }),
    };

    // It seems the response from the deployed thinger doesnt match the latest updates
    // so we'll just do some transforming here temporarily until things solidify
    const response = await axios(config);

    return formatTotesInfo(response.data, orderId);
};


const formatMultizoneTask = (task) => {
    if (lodash.isEmpty(task)) {
        return undefined;
    }

    task = camelCaseKeys(task);
    task.totes = task.totes.map((tote) => camelCaseKeys(tote, true));
    task.steps = task.steps.map((step) => {
        const {
            requestedQuantity,
            requestedWeight,
            ...stepValue
        } = camelCaseKeys(step, true);
        return {
            ...stepValue,
            requested: {
                quantity: requestedQuantity,
                weight: requestedWeight,
            },
        };
    });

    task.items = lodash.reduce(
        task.items,
        (acc, value, key) => {
            const {
                productBarcodes: barcodes,
                productData,
                ...item
            } = camelCaseKeys(value, true);

            return {
                ...acc,
                [key]: {
                    ...productData,
                    ...item,
                    barcodes,
                },
            };
        },
        {}
    );

    return task;
};


const getMultizoneAvailableWork = async ({ envConfig, mfcId, xToken }) => {
    const baseURL = getBaseUrl(envConfig);

    const config = {
        method: 'GET',
        baseURL,
        url: `/api/retailers/${envConfig.retailerCodename}/mfcs/${mfcId}/multizone/availableWork`,
        headers: {
            'X-Token': xToken,
            'X-Env-Type': envConfig.environmentTypeId,
            'X-Retailer-Id': envConfig.retailerCodename,
            // uncomment this line to use the mock-mode in the mobile-app-backend
            // 'X-Mock-Mode': 'true',
        },
    };
    const { data } = await axios(config);
    const { activeZoneProfile, assignedTask } = camelCaseKeys(data);

    return {
        activeZoneProfile: camelCaseKeys(activeZoneProfile, true),
        assignedTask: formatMultizoneTask(assignedTask),
    };
};

const mobileBackend = {
    formatFulfillmentTask,
    getBaseUrl,
    getMultizoneAvailableWork,
    getTaskTotes,
    assignFulfillmentTask,
};

export default mobileBackend;
