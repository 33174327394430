import lodash from 'lodash';
import barcodeUtils from '@takeoff-com/barcode-utils';

export const generateProductBarcodeFromValueOrig = ({ scannedParsedBarcode, barcodeConfig, productBarcodes, value }) => {
    const decimals = barcodeConfig.variableMeasureNumberOfDecimalPlaces || 0;
    const regex = new RegExp(`0{${barcodeConfig.variableMeasureNumberOfMeasureDigits}}\\d?$`);
    const roundedValue = lodash.round(lodash.toNumber(value), decimals);
    const barcodeMeasure = lodash.padStart(
        lodash.round(roundedValue * (10 ** decimals)),
        barcodeConfig.variableMeasureNumberOfMeasureDigits,
        '0'
    );
    for (const barcode of productBarcodes) {
        const barcodePrefix = barcode.replace(regex, '');
        let newBarcode = `${barcodePrefix}${barcodeMeasure}`;
        newBarcode = `${newBarcode}${barcodeUtils.calculateCheckDigit(newBarcode)}`;
        const parsedBarcode = barcodeUtils.parseProductBarcode({
            barcode: newBarcode,
            variableMeasureConfig: {
                barcodePrefixes: barcodeConfig.variableMeasurePrefixes,
                hasMiddleCheckDigit: false, // since we are building the barcode from the value, no middle check digit will be present
                numberOfDecimalPlaces: decimals,
                numberOfMeasureDigits: barcodeConfig.variableMeasureNumberOfMeasureDigits,
            },
        });
        if (!parsedBarcode.errorMessage && parsedBarcode.variableMeasure?.value === roundedValue) {
            return parsedBarcode;
        }
    }

    // if everything else fails we will add the `variableMeasure` value manually to the original parsedBarcode e.g. PLU.
    return {
        ...scannedParsedBarcode,
        variableMeasure: {
            barcode: scannedParsedBarcode?.barcode,
            value: parseFloat(value),
        },
    };
};

export const generateProductBarcodeFromValue = ({ scannedParsedBarcode, barcodeConfig, productBarcodes, value }) => {
    const decimals = barcodeConfig.variableMeasureNumberOfDecimalPlaces || 0;
    const regex = new RegExp(`0{${barcodeConfig.variableMeasureNumberOfMeasureDigits}}\\d?$`);
    const roundedValue = lodash.round(lodash.toNumber(value), decimals);
    const barcodeMeasure = lodash.padStart(
        lodash.round(roundedValue * (10 ** decimals)),
        barcodeConfig.variableMeasureNumberOfMeasureDigits,
        '0'
    );
    for (const productBarcode of productBarcodes) {
        const productBarcodeParsed = barcodeUtils.parseProductBarcode({
            barcode: productBarcode,
            variableMeasureConfig: {
                barcodePrefixes: barcodeConfig.variableMeasurePrefixes,
                hasMiddleCheckDigit: false, // since we are building the barcode from the value, no middle check digit will be present
                numberOfDecimalPlaces: decimals,
                numberOfMeasureDigits: barcodeConfig.variableMeasureNumberOfMeasureDigits,
            },
        });

        const barcode = productBarcodeParsed.barcode;
        // we ensure we add digits if missing, otherwise we can construct a barcode that doesn't allow to add measure.

        const barcodePrefix = barcode.replace(regex, '');
        const newBarcodeWithMeasure = `${barcodePrefix}${barcodeMeasure}`;
        const newBarcodeWithMeasureAndDigit = `${newBarcodeWithMeasure}${barcodeUtils.calculateCheckDigit(newBarcodeWithMeasure)}`;

        const parsedBarcode = barcodeUtils.parseProductBarcode({
            barcode: newBarcodeWithMeasureAndDigit,
            variableMeasureConfig: {
                barcodePrefixes: barcodeConfig.variableMeasurePrefixes,
                hasMiddleCheckDigit: false, // since we are building the barcode from the value, no middle check digit will be present
                numberOfDecimalPlaces: decimals,
                numberOfMeasureDigits: barcodeConfig.variableMeasureNumberOfMeasureDigits,
            },
        });
        if (!parsedBarcode.errorMessage && parsedBarcode.variableMeasure?.value === roundedValue) {
            return parsedBarcode;
        }
    }

    // if everything else fails we will add the `variableMeasure` value manually to the original parsedBarcode e.g. PLU.
    return {
        ...scannedParsedBarcode,
        variableMeasure: {
            barcode: scannedParsedBarcode?.barcode,
            value: parseFloat(value),
        },
    };
};


export default {
    generateProductBarcodeFromValue,
};
