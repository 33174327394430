import lodash from 'lodash';

import barcodeUtils from '@takeoff-com/barcode-utils';

// copy pasting utils from TOMA, keeping them as is for ease re-use.

// utils/config.js
export const getVariableMeasureConfig = (barcodeConfig) => {
    const variableMeasureConfig = {
        barcodePrefixes: barcodeConfig.variableMeasurePrefixes,
        numberOfMeasureDigits: barcodeConfig?.variableMeasureNumberOfMeasureDigits,
        numberOfDecimalPlaces: barcodeConfig?.variableMeasureNumberOfDecimalPlaces || 0,
        hasMiddleCheckDigit: barcodeConfig?.variableMeasureHasMiddleCheckDigit,
    };
    return variableMeasureConfig;
};

// product.js
export const shouldPickProductByAggregatedWeight = ({ barcodeConfig, productBarcodes }) => {
    // If the product has at least one PLU barcode we infer that we should be
    // picking it by aggregated weight.  This logic may change!

    const variableMeasureConfig = getVariableMeasureConfig(barcodeConfig);
    return lodash.some(
        productBarcodes,
        (barcode) => barcodeUtils.parseProductBarcode({ barcode, variableMeasureConfig }).type === 'SHORT',
    );
};

// shelf.js
export const validShelfRegex = /^(\d{4}|\d{2})?([A-Z])(\d{2})(\d{3})(\d{1})([A-Z]+)$/; // NOSONAR

export const parseAddressId = (addressId) => {
    const match = validShelfRegex.exec(addressId);
    if (match) {
        const [ , prefix, sleepingArea, aisle, bay, shelf, position ] = match;
        return {
            prefix,
            sleepingArea,
            aisle,
            bay,
            shelf,
            position,
        };
    }
    return;
};

// lang.js
const _transformKeys = (object, deep, seen, transformer) => {
    if (seen.has(object)) {
        // Preserve references and avoid infinite recursion
        return seen.get(object);
    }

    if (lodash.isPlainObject(object)) {
        const newObject = lodash.mapKeys(object, lodash.rearg(transformer, [ 1 ]));
        seen.set(object, newObject);

        lodash.forEach(newObject, (value, key) => {
            if (deep) {
                newObject[key] = _transformKeys(value, true, seen, transformer);
            }
            else if (value === object) {
                newObject[key] = newObject;
            }
        });

        return newObject;
    }
    else if (deep && lodash.isArray(object)) {
        const newArray = [];
        seen.set(object, newArray);

        lodash.forEach(object, (value, index) => newArray[index] = _transformKeys(value, true, seen, transformer));

        return newArray;
    }
    else {
        return object;
    }
};

export const camelCaseKeys = (object, deep = false) => _transformKeys(object, deep, new Map(), lodash.camelCase);
export const snakeCaseKeys = (object, deep = false) => _transformKeys(object, deep, new Map(), lodash.snakeCase);

