import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Box, IconButton, TextField, Tooltip } from '@mui/material';
import Barcode from 'react-barcode';
import lodash from 'lodash';
import barcodeUtils from '@takeoff-com/barcode-utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopy from '@mui/icons-material/ContentCopy';

import { getVariableMeasureConfig } from '../utils/toma';
import { generateProductBarcodeFromValue } from '../utils/product';
import * as gtin from 'gtin';

const ClipboardButton = ({ text }) => {
    const [ showSuccess, setShowSuccess ] = useState(false);
    useEffect(() => {
        if (showSuccess) {
            const timeout = setTimeout(() => setShowSuccess(false), 750);
            return () => clearTimeout(timeout);
        }
    }, [ showSuccess ]);

    return (
        <IconButton
            disabled={showSuccess}
            onClick={() => {
                navigator.clipboard.writeText(text);
                setShowSuccess(true);
            }}
        >
            {!!showSuccess && (
                <Tooltip
                    arrow
                    disableHoverListener
                    open
                    title="Copied!"
                >
                    <CheckCircleIcon color="success" />
                </Tooltip>
            )}
            {!showSuccess && (
                <ContentCopy />
            )}
        </IconButton>
    );
};

const BarcodeBox = ({ title, variableMeasureConfig, value }) => {
    const scannedParsedBarcode = barcodeUtils.parseProductBarcode({ barcode: value, variableMeasureConfig });
    const { type } = scannedParsedBarcode ;

    // barcodeUtils reports values as GTIN-12, but not trimming to generate 13 digit barcode.
    //const format = type === 'GTIN-12' && barcodeLength === 13 ? 'EAN13' : undefined;
    const { barcode, format } = useMemo(() => {
        let barcodeValue = scannedParsedBarcode.barcode;
        let formatValue = undefined;

        if (type === 'GTIN-12') {
            const barcodeLength = lodash.size(barcodeValue);
            if (barcodeLength === 14) {
                const leadingZerosReplaceRegex = RegExp('^0{0,1}');
                barcodeValue = barcodeValue.replace(leadingZerosReplaceRegex, '');
            }
            if (lodash.size(barcodeValue) === 13) {
                formatValue = 'EAN13';
            }
        }

        if (type === 'SHORT') {
            barcodeValue = gtin.minify(barcodeValue);
        }

        return {
            barcode: barcodeValue,
            format: formatValue,
        };
    }, [ scannedParsedBarcode.barcode, type ]);

    return (
        <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Box style={{ fontSize: 20, fontWeight: 'bold', justifyContent: 'center', textAlign: 'center' }}>
                <div> {title} <ClipboardButton text={barcode} /></div>
            </Box>
            <Barcode
                format={format}
                value={barcode}
            />
        </Box>
    );
};

const ProductBarcodes = ({ barcode, isWeightVariable, barcodeConfig, requestedWeight, productWeight, productBarcodes }) => {
    const variableMeasureConfig = getVariableMeasureConfig(barcodeConfig);

    const scannedParsedBarcode = barcodeUtils.parseProductBarcode({ barcode, variableMeasureConfig });
    const expectedWeight = requestedWeight || productWeight || 0;
    const [ measureValue, setMeasureValue ] = React.useState(expectedWeight.toFixed(barcodeConfig.variableMeasureNumberOfDecimalPlaces));

    const { overweightBarcode, withMeasureBarcode, underweightBarcode, overweightMeasure, underweightMeasure } = React.useMemo(() => {
        if (!lodash.isNumber(lodash.toNumber(measureValue))) {
            return {};
        }

        const barcodeFromValue = generateProductBarcodeFromValue({ scannedParsedBarcode, barcodeConfig, productBarcodes, value: measureValue });
        const generated = barcodeFromValue?.originalBarcode;

        const overweightMeasureValue = (expectedWeight * ((100 + barcodeConfig.weightTolerancePlus + 5) / 100)).toFixed(barcodeConfig.variableMeasureNumberOfDecimalPlaces);
        const underweightMeasureValue = (expectedWeight * ((100 - barcodeConfig.weightToleranceMinus - 5) / 100)).toFixed(barcodeConfig.variableMeasureNumberOfDecimalPlaces);

        const overweight = generateProductBarcodeFromValue({ scannedParsedBarcode, barcodeConfig, productBarcodes, value: overweightMeasureValue })?.originalBarcode;
        const underweight = generateProductBarcodeFromValue({ scannedParsedBarcode, barcodeConfig, productBarcodes, value: underweightMeasureValue })?.originalBarcode;

        return { withMeasureBarcode: generated, overweightBarcode: overweight, underweightBarcode: underweight, overweightMeasure: overweightMeasureValue, underweightMeasure: underweightMeasureValue };
    }, [ barcodeConfig, expectedWeight, measureValue, productBarcodes, scannedParsedBarcode ]);

    const canEmbedWeightVar = overweightBarcode !== barcode;


    return (
        <div style={{ borderTop: '1px dotted #f00' }}>
            <Box style={{ fontSize: 14 }}>Product barcode</Box>

            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <BarcodeBox title={isWeightVariable && canEmbedWeightVar ? 'without measure' : 'product barcode'} variableMeasureConfig={variableMeasureConfig} value={barcode} />
                {isWeightVariable && canEmbedWeightVar && (
                    <>
                        <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <TextField
                                defaultValue={measureValue}
                                onChange={(event) => setMeasureValue(event.target.value)}
                                label="Measure Value"
                                style={{ width: 120 }}
                                helperText={barcodeConfig.variableMeasureAssumedType}
                            />
                        </Box>
                        {withMeasureBarcode && (
                            <BarcodeBox
                                title="with measure"
                                variableMeasureConfig={variableMeasureConfig}
                                value={withMeasureBarcode}
                                warning={withMeasureBarcode === barcode ? 'unable to add var measure' : undefined}
                            />
                        )}
                        {barcodeConfig.variableMeasureAssumedType === 'weight' && (
                            <Box>
                                <BarcodeBox
                                    title={`overweight ${overweightMeasure}`}
                                    variableMeasureConfig={variableMeasureConfig}
                                    value={overweightBarcode}
                                    warning={overweightBarcode === barcode ? 'unable to add var measure' : undefined}
                                />

                                <BarcodeBox
                                    title={`underweight ${underweightMeasure}`}
                                    variableMeasureConfig={variableMeasureConfig}
                                    value={underweightBarcode}
                                    warning={overweightBarcode === barcode ? 'unable to add var measure' : undefined}

                                />

                            </Box>
                        )}
                    </>
                )}

                {isWeightVariable && !canEmbedWeightVar && (
                    <Alert severity="warning">Unable to embed variable measure to the barcode</Alert>
                )}
            </Box>
        </div>
    );
};


export default ProductBarcodes;
