
import axios from 'axios';
import { camelCaseKeys } from '../utils/toma';

const getBaseUrl = (envConfig) => {
    const baseDomain = envConfig.environmentTypeId === 'ode' ? envConfig.baseDomain : 'tom.takeoff.com';
    return `https://oms-${envConfig.retailerCodename}-${envConfig.environmentTypeId}.${baseDomain}`;
};
const getOrderData = async ({ orderId, envConfig, xToken }) => {

    const response = await axios({
        method: 'GET',
        baseURL: getBaseUrl(envConfig),
        url: `v3/order/${orderId}`,
        headers: { 'X-Token': xToken },
    });
    return camelCaseKeys(response.data.response || {}, true);
};

export default { getOrderData };
