import React from 'react';
import { Box, Snackbar } from '@mui/material';
import { Button, Spinner } from '@takeoff-com/core-ui';

const LoadingButton = ({ dismissError, error, loading, onClick, title, width }) => {
    return (
        <div style={{ position: 'relative', width: width + 6 }}>
            <Button
                color="primary"
                onClick={onClick}
                style={{ width }}
            >
                {title}
            </Button>
            {error && (
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={dismissError}
                    message={error}
                />
            )}
            {loading && (
                <Box
                    style={{
                        position: 'absolute',
                        backgroundColor: '#FFFFFFDD',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                >
                    <Spinner />
                </Box>
            )}
        </div>
    );
};


export default LoadingButton;
