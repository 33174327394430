import React from 'react';
import lodash from 'lodash';

import { Box, Link } from '@mui/material';
import { shouldPickProductByAggregatedWeight } from '../utils/toma';
import Barcode from 'react-barcode';
import CheckCircleTwoTone from '@mui/icons-material/CheckCircleTwoTone';
import SyncDisabledTwoToneIcon from '@mui/icons-material/SyncDisabledTwoTone';

import ProductBarcodes from '../common/ProductBarcode';
import Section from '../common/Section';

const Step = ({ barcodeConfig, task, step, configFormValues }) => {
    const taskItem = task.items[step.productId];

    const pickingMode = React.useMemo(() => {
        if (!taskItem.productIsWeightVariable) {
            return 'eaches';
        }

        if (step.requested?.weight) {
            return 'requestedWeight';
        }
        if (shouldPickProductByAggregatedWeight({ barcodeConfig, productBarcodes: taskItem.barcodes })){
            return 'aggregatedWeight';
        }

        if (barcodeConfig.variableMeasureAssumedType === 'weight') {
            return 'prepackedWeightEmbedded';
        }

        return 'prepackedPriceEmbedded';

    }, [ barcodeConfig, step.requested?.weight, taskItem.barcodes, taskItem.productIsWeightVariable ]);

    const barcode = lodash.first(taskItem.barcodes);

    // we should get these two values from actions in the long run.
    // for now, we just assume is the incremental one
    const tote = lodash.find(task.totes, (t) => t.number === step.toteNumber);

    const toteBarcode = tote?.tote || `${99900000000000 + (step.toteNumber)}`;

    const additionalTotes = tote ? lodash.filter(task.totes, (t) => !!t.parentOrderToteId && t.parentOrderToteId === tote.orderToteId) : [];

    const isToteFromActions = !!tote;

    return (
        <Box className="multizone-step-container">
            <Box className="multizone-step-info">
                <Box className="multizone-step-left-panel">
                    <Box className="section-title">Step {step.number} - {step.addressId}</Box>
                    <Box className="section-heading-text picking-mode">{pickingMode}</Box>
                    <Box className="section-heading-text" style={{ width: 400 }}>{taskItem.productName || 'flop'}</Box>
                    {tote.orderId && (
                        <Box className="section-heading-text" style={{ width: 400 }}>
                            <Link href={`https://${configFormValues.retailerCodename}-${configFormValues.environmentTypeId}.tom.takeoff.com/orders/details/?id=${tote.orderId}`} target="_blank">
                                {tote.orderId}
                            </Link>
                        </Box>
                    )}

                    {step.lineNote && (
                        <Box className="line-note">
                            {step.lineNote}
                        </Box>
                    )}
                </Box>
                {!!taskItem.image && (
                    <img
                        style={{
                            margin: 6,
                            bordeRadius: 20,
                        }}
                        src={taskItem.image}
                        alt="Product"
                        height={200}
                        width={200}
                    >
                    </img>
                )}
            </Box>

            {barcode && (
                <div>
                    <ProductBarcodes
                        barcode={barcode}
                        productBarcodes={taskItem.barcodes}
                        isWeightVariable={taskItem.productIsWeightVariable}
                        barcodeConfig={barcodeConfig}
                        requestedWeight={step.requested.weight}
                        productWeight={taskItem.retailItem?.weight.weight}
                    />

                    <div style={{ borderTop: '1px dotted #f00' }}>
                        <Box style={{ fontSize: 14 }}>Tote barcode</Box>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="multizone-tote-container">
                                <div className="multizone-tote-icon-container">
                                    {!isToteFromActions
                                        ? <SyncDisabledTwoToneIcon />
                                        : <CheckCircleTwoTone sx={{ color: 'green' }} />}
                                </div>

                                <Barcode value={toteBarcode} />
                            </div>
                            {lodash.map((additionalTotes), (addTote) => (
                                <div className="multizone-tote-container">
                                    <div className="multizone-tote-icon-container">
                                        <CheckCircleTwoTone sx={{ color: 'green' }} />
                                    </div>

                                    <Barcode value={addTote.tote} lineColor="#15156a" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            )}
        </Box>
    );
};

const PickingSteps = ({ task, barcodeConfig, configFormValues }) => {
    return (
        <Section title={`Picking (#steps: ${lodash.size(task.steps)})`}>
            {lodash.map(task.steps, (step, stepIndex) => (
                <Step
                    barcodeConfig={barcodeConfig}
                    task={task}
                    step={step}
                    stepIndex={stepIndex}
                    configFormValues={configFormValues}
                />
            ))}
        </Section>
    );
};

export default PickingSteps;
