import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Barcode from 'react-barcode';
import mobileBackend from './services/mobileBackend';
import serviceCatalog from './services/serviceCatalog';
import lodash from 'lodash';

import './App.scss';
import { Box, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Select, Text } from '@takeoff-com/core-ui';
import LoadingButton from './LoadingButton';
import ProductBarcodes from './common/ProductBarcode';
import oms from './services/oms';

const Step = ({ task, step, barcodeConfig, configFormValues }) => {
    const taskItem = task.items[step.productId];
    const [ barcode, setBarcode ] = useState(lodash.first(taskItem.productBarcodes));

    const barcodeMenuItems = React.useMemo(() => lodash.map(
        taskItem.productBarcodes,
        (bcValue) => ({ text: bcValue, value: bcValue })
    ), [ taskItem.productBarcodes ]);

    return (
        <Box className="section-wrapper">
            <Box style={{ display: 'flex', flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1, borderBottomStyle: 'solid', justifyContent: 'center' }}>
                <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingLeft: 10, paddingRight: 20 }}>
                    <Box className="section-title">Step {step.number} - {step.addressId}</Box>
                    <Box style={{ width: 400 }}>
                        <Link className="section-heading-text" href={`https://${configFormValues.retailerCodename}-${configFormValues.environmentTypeId}.tom.takeoff.com/products/view?id=${taskItem.productId}`} target="_blank">
                            {taskItem.productName || 'flop'}
                        </Link>
                    </Box>
                </Box>
                {!!taskItem.productImageUri && (
                    <img
                        src={taskItem.productImageUri}
                        alt="Product"
                        height={200}
                        width={200}
                    >
                    </img>
                )}
            </Box>
            {lodash.size(taskItem.productBarcodes) > 1
                  && (
                      <Select
                          style={{ marginBottom: '1rem' }}
                          value={barcode}
                          label="Barcode"
                          menuItems={barcodeMenuItems}
                          onChange={(event) => setBarcode(event.target.value)}
                      />
                  )}
            {barcode && (
                <ProductBarcodes
                    productBarcodes={taskItem.productBarcodes}
                    barcode={barcode}
                    isWeightVariable={taskItem.productIsWeightVariable}
                    barcodeConfig={barcodeConfig}
                    requestedWeight={step.requested.weight}
                    productWeight={taskItem.productWeight}
                />
            )}

        </Box>
    );
};

const ManualFulfillment = ({ envConfig, defaultTask, barcodeConfig, configFormValues }) => {
    const [ task, setTask ] = useState(defaultTask);
    const { xToken, mfcId, retailerCodename, environmentTypeId } = configFormValues;
    const [ showRawData, setShowRawData ] = useState(false);

    const [ stagingLocations, setStagingLocations ] = useState([]);

    useEffect(() => {
        if (mfcId && xToken) {
            serviceCatalog.getStagingLocations({
                envConfig,
                mfcId,
                xToken,
            }).then((data) => {
                setStagingLocations(data);
            });
        }
    }, [ xToken, mfcId, envConfig ]);

    const [ gettingAssignment, setGettingAssignment ] = useState(false);
    const [ getAssignmentError, setGetAssignmentError ] = useState();

    const getAssignment = useCallback(() => {
        if (retailerCodename && xToken && mfcId && environmentTypeId) {
            if (!gettingAssignment) {
                setGettingAssignment(true);
                setGetAssignmentError(undefined);
                mobileBackend.assignFulfillmentTask({ xToken, envConfig, mfcId })
                    .then((assignedTask) => {
                        if (!assignedTask) {
                            setGetAssignmentError('No tasks to assign');
                        }

                        setTask(assignedTask);
                    })
                    .catch((err) => {
                        setGetAssignmentError(err.response?.data?.error || err.message);
                    })
                    .finally(() => setGettingAssignment(false));
            }
        }
        else {
            setGetAssignmentError('Set all the required config values in the dev bar to get a task');
        }
    }, [ retailerCodename, xToken, mfcId, environmentTypeId, gettingAssignment, envConfig ]);

    const [ fetchingTotes, setFetchingTotes ] = useState(false);
    const [ fetchTotesError, setFetchTotesError ] = useState();
    const [ osrTotesToCollect, setOsrtotesToCollect ] = useState([]);
    const fetchTotes = () => {
        setFetchingTotes(true);
        setFetchTotesError(undefined);
        mobileBackend
            .getTaskTotes({ envConfig, mfcId, orderId: task.orderId, xToken, fulfillmentTaskId: task.id })
            .then(({ totes }) => { setOsrtotesToCollect(totes) })
            .catch((err) => {
                setFetchTotesError(err.response?.data?.error || err.message);
            })
            .finally(() => setFetchingTotes(false));
    };

    const [ fetchingOMSData, setFetchingOMSData ] = useState(false);
    const [ fetchOMSDataError, setFetchOMSDataError ] = useState();
    const [ omsData, setOMSData ] = useState([]);

    const fetchOMSData = () => {
        setFetchingOMSData(true);
        setFetchOMSDataError(undefined);
        oms.getOrderData({ orderId: task.orderId, envConfig, xToken }).then((orderData) => { setOMSData(orderData) })
            .catch((err) => {
                setFetchOMSDataError(err.response?.data?.error || err.message);
            })
            .finally(() => setFetchingOMSData(false));
    };

    const pickedUpcData = useMemo(() => {
        return lodash.map(omsData?.lineItems, ({ ecomItemId, tomItems }) => {
            return {
                ecomItemId,
                productName: task?.items[ecomItemId]?.productName || '-',
                decision: tomItems[0]?.decision,
            };
        });
    }, [ omsData?.lineItems, task?.items ]);

    const orderTotes = useMemo(() => {
        const osrTotes = [];
        const manualTotes = [];
        lodash.forEach(task?.totes, (tote) => {
            if (tote.manual) {
                manualTotes.push(tote);
            }
            else {
                osrTotes.push(tote);
            }
        });

        return {
            osrTotes,
            manualTotes,
        };
    }, [ task?.totes ]);


    return (
        <>
            <Box style={{ display: 'flex', position: 'relative', padding: 10, flexDirection: 'column' }}>
                <LoadingButton
                    width={400}
                    title="Get Assignment or Refresh"
                    onClick={getAssignment}
                    loading={gettingAssignment}
                    error={getAssignmentError}
                    dismissError={() => setGetAssignmentError(undefined)}
                />
            </Box>
            {task && (
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                    <Box>
                        <Box style={{ fontSize: 50 }}>{'Order: '}
                            <Link href={`https://${configFormValues.retailerCodename}-${configFormValues.environmentTypeId}.tom.takeoff.com/orders/details/?id=${task.orderId}`} target="_blank">
                                {task.orderId}
                            </Link>
                        </Box>
                        {lodash.map(task.steps, (step, index) => {
                            return (
                                <Step
                                    configFormValues={configFormValues}
                                    step={step}
                                    task={task}
                                    key={index}
                                    barcodeConfig={barcodeConfig}
                                />
                            );
                        })}

                        <Box className="section-wrapper">
                            <Box className="section-title">Picked UPC Codes</Box>
                            <LoadingButton
                                width={200}
                                title="Refresh Picked UPC"
                                onClick={fetchOMSData}
                                loading={fetchingOMSData}
                                error={fetchOMSDataError}
                                dismissError={() => setFetchOMSDataError(undefined)}
                            />
                            <Box className="code-output">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Product
                                            </TableCell>
                                            <TableCell>
                                                Picked UPC
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lodash.map((pickedUpcData), (item) => (
                                            <TableRow key={item.ecomItemId}>
                                                <TableCell>
                                                    <Text format="h4"> {item.productName}</Text> <br />
                                                    {item.ecomItemId}
                                                </TableCell>
                                                <TableCell>
                                                    <pre>
                                                        {JSON.stringify(item.decision, null, 4)}
                                                    </pre>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>

                        </Box>

                        <Box className="section-wrapper">
                            <Box className="section-title">OSR Totes: {lodash.size(osrTotesToCollect)}</Box>
                            <LoadingButton
                                width={200}
                                title="Get OSR Totes"
                                onClick={fetchTotes}
                                loading={fetchingTotes}
                                error={fetchTotesError}
                                dismissError={() => setFetchTotesError(undefined)}

                            />
                            <Box className="box-barcode-list-horizontal">
                                {lodash.map(osrTotesToCollect, (_, tote) => (
                                    <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        <Barcode value={tote} />
                                    </Box>
                                ))}
                            </Box>
                            <Box className="section-title">Manual Totes: {orderTotes.manualTotes.length}</Box>
                            <Box className="box-barcode-list-horizontal">
                                {lodash.map(orderTotes.manualTotes, (tote, toteIndex) => (
                                    <Box style={{ paddingLeft: 10, paddingRight: 10 }} key={toteIndex}>
                                        <Barcode value={tote.tote} />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box className="section-wrapper">
                            <Box className="section-title">Staging Locations</Box>
                            <Box style={{ paddingLeft: 20, textAlign: 'center' }}>
                                <Box className="section-heading-text">Assigned Staging Location:</Box>
                                {!task.stagingLocation && (
                                    <Box>None</Box>
                                )}
                                {!!task.stagingLocation && (
                                    <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        <Barcode value={task.stagingLocation} />
                                    </Box>
                                )}
                            </Box>
                            <Box style={{ paddingLeft: 20, textAlign: 'center' }}>
                                <Box className="section-heading-text">All Staging Locations:</Box>
                                <Box className="box-barcode-list-horizontal">
                                    {lodash.map(stagingLocations, (stagingLocation, index) => {
                                        return (
                                            <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <Barcode value={stagingLocation.stagingLocationCode} key={index} />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ paddingBottom: 50, minWidth: 1000, borderTop: 'outset', borderColor: 'red' }}>
                            <Box className="section-title" style={{ cursor: 'pointer' }} onClick={() => setShowRawData(!showRawData)}>Raw Data (click to toggle)</Box>
                            {showRawData && (
                                <Box>
                                    <Box>
                                        <Box>Barcode Config</Box>
                                        <pre>{JSON.stringify(barcodeConfig, null, 2)}</pre>
                                    </Box>
                                    <Box>
                                        Task
                                        <pre>{JSON.stringify(task, null, 2)}</pre>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box style={{ paddingLeft: 100, alignItems: 'center' }}>
                        <Box className="section-title" style={{ textAlign: 'center' }}>Free Totes</Box>
                        <Box style={{ paddingLeft: 10, paddingRight: 10, display: 'flex', flexDirection: 'column' }}>
                            {lodash.map(lodash.range(1, 6), (number, i) => (
                                <Barcode value={`${99900000000000 + number}`} key={i} />
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ManualFulfillment;
