import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Text } from '@takeoff-com/core-ui';

const Section = ({ title, children }) => {
    return (
        <Accordion
            style={{
                minWidth: 1000,
                paddingBottom: 15,
                marginTop: 8,
                border: '2px solid #cdcdcd',
                borderRadius: 10,
            }}
            defaultExpanded
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Text format="h3-display">{title}</Text>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};


export default Section;
