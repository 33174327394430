import React from 'react';
import Section from '../common/Section';
import Cart from '../common/Cart';
import lodash from 'lodash';
import Barcode from 'react-barcode';
import { Text } from '@takeoff-com/core-ui';

const Staging = ({ stagingLocation, rowCount, columnCount, totes }) => {
    const toteValues = React.useMemo(() => totes.map(({ tote, expectedStagingLocation }, pos) => {
        if (pos >= (lodash.size(totes))) {
            return null;
        }
        const licensePlate = tote ? tote : `${99900000000000 + (pos + 1)}`;
        if (expectedStagingLocation === stagingLocation) {
            return licensePlate;
        }

        return null;
    }), [ stagingLocation, totes ]);

    return (
        <Section title={`Staging Totes at: ${stagingLocation}`}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: '5px 5px 7px rgba(33,33,33,.7)',
                    margin: 50,
                    marginTop: 0,
                    padding: 16,
                    border: '1px solid #cdcdcd',
                }}
            >
                <Text format="h3-display">Location: {stagingLocation}</Text>
                <Barcode value={stagingLocation} />
            </div>

            <Cart
                staging
                rowCount={rowCount}
                columnCount={columnCount}
                toteValues={toteValues}
                isToteScanned={(totePos) => totes[totePos]?.staged}
            />
        </Section>
    );
};


export default Staging;
