import React from 'react';
import lodash from 'lodash';
import SyncDisabledTwoToneIcon from '@mui/icons-material/SyncDisabledTwoTone';
import CheckCircleTwoTone from '@mui/icons-material/CheckCircleTwoTone';
import Barcode from 'react-barcode';
import { TextField } from '@takeoff-com/core-ui';

const CartHandle = ({ height }) => {
    return (
        <div
            style={{
                height: height + 12,
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    width: 18,
                    height: 12,
                    backgroundColor: 'red',
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12,
                }}
            />
            <div
                style={{
                    backgroundColor: 'red',
                    width: 6,
                    height,
                }}
            />

        </div>
    );
};


const CartTote = ({ scanned, value, idx, additionalTote }) => {
    if (value) {
        return (
            <div style={{ position: 'relative', maxWidth: 244, marginRight: 6, marginBottom: 6 }}>
                <div style={{ right: 0, padding: 3, position: 'absolute', zIndex: 1, backgroundColor: 'white', borderRadius: 16, display: 'flex' }}>
                    {!scanned ? <SyncDisabledTwoToneIcon /> : <CheckCircleTwoTone sx={{ color: 'green' }} />}
                </div>
                <Barcode value={value} key={idx} lineColor={additionalTote ? '#15156a' : '#000000'} />
            </div>
        );
    }
    return <div style={{ width: 244, height: 142, marginRight: 6, marginBottom: 6, backgroundColor: '#f0f0f0', borderRadius: 15 }} key={idx} />;
};

const Cart = ({ staging, rowCount, columnCount, toteValues, isToteScanned, startAt, setStartAt, showStartAt }) => {
    const additionalTotes = toteValues.filter((_, pos) => pos >= columnCount * rowCount);
    const showAdditionalTotes = !lodash.isEmpty(additionalTotes) && staging;

    const error = isNaN(parseInt(startAt, 10)) ? 'Value needs to be an integer' : undefined;

    return (
        <div>
            {showStartAt && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TextField
                        error={error}
                        hasError={!!error}
                        label="Start at"
                        value={startAt}
                        onChange={({ target }) => setStartAt(target.value)}
                    />
                </div>
            )
            }
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <CartHandle height={(rowCount + (showAdditionalTotes ? 1 : 0)) * 150} />
                <div style={{ marginLeft: 8 }}>
                    {showAdditionalTotes && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {toteValues.map((tote, idx) => {
                                if (idx < columnCount * rowCount) {
                                    return;
                                }

                                return (
                                    <CartTote
                                        key={idx}
                                        scanned={isToteScanned(idx)}
                                        idx={idx}
                                        value={tote}
                                        additionalTote
                                    />
                                );
                            })}
                        </div>
                    )}
                    {lodash.range(rowCount).map((rowIndex) => {
                        return (
                            <div key={rowIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                                {lodash.range(columnCount).map((columnIndex) => {
                                    const idx = (rowIndex * columnCount) + columnIndex;
                                    const scanned = isToteScanned(idx);
                                    return (
                                        <CartTote
                                            key={idx}
                                            scanned={scanned}
                                            idx={idx}
                                            value={toteValues[idx]}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};


export default Cart;
