const toteRegex = /^(?:998|999)\d{11}$/;

const isTargetTote = (toteId) => {
    return !!toteId && toteRegex.test(toteId);

};

export default {
    isTargetTote,
};
